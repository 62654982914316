import React from 'react';
import { CircularProgress, Select } from '@mui/material';
import { ReactComponent as StepIcon } from '../../assets/svg/4StepsSterpper-step3.svg';
import { Formik } from 'formik';
import { registerStep3ValidationSchema } from '../../shared/schemas/facilities';
import {
  InputChangeState,
  TextInput,
  FACILITY_COUNTRY_OPTIONS,
  FACILITY_LANGUAGES_OPTIONS,
} from '@mola/client-components';

const initialStateV = {
  country: 'Kenya',
  city: '',
  address: '',
  supportedLanguages: 'English',
};

const RegisterFacilityStepThree: React.FC<RegisterFacilityStepThreeProps> = ({
  goNext,
  goBack,
  initialState,
}) => {
  const errorMessage = '';
  const isLoading = false;
  return (
    <Formik
      onSubmit={() => {
        // _login(values);
      }}
      validateOnChange
      initialValues={initialState}
      validationSchema={registerStep3ValidationSchema}
    >
      {(props) => {
        const { values, touched, errors, handleBlur, handleChange, isValid } = props;

        const isFormValid =
          isValid &&
          Object.values(errors).length === 0 &&
          values.country.length > 0 &&
          values.city.length > 0 &&
          values.address.length > 0 &&
          values.supportedLanguages &&
          values.supportedLanguages.length > 0;

        return (
          <div className="w-96">
            <h2 className="font-bold text-2xl py-6">Register facility </h2>
            <p className="text-primary text-xl pb-2 flex flex-row justify-between">
              Address & language Details <StepIcon className="mt-3" />
            </p>
            {errorMessage && <p className="text-sm text-red-600">({errorMessage})</p>}
            <div className="mt-4">
              <label
                htmlFor="facility-category"
                className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
              >
                Country <span className="text-gray-400">*</span>
              </label>
              <Select
                name="country"
                id="country-select"
                className="rounded-md h-10 mt-2 w-full"
                onChange={handleChange}
                native
                value={values.country}
                size="small"
              >
                {FACILITY_COUNTRY_OPTIONS.map((el) => (
                  <option
                    value={el.value}
                    key={Math.random()}
                    className="hover:bg-grey-4 cursor-pointer"
                  >
                    {el.display}
                  </option>
                ))}
              </Select>
            </div>
            <TextInput
              labelKey="City"
              forText="city"
              name="city"
              isRequired
              classes={{}}
              placeHolder="City"
              onChange={(value, event) => {
                handleChange(event);
              }}
              onBlur={handleBlur}
              changeState={
                touched.city && errors.city ? InputChangeState.INVALID : InputChangeState.VALID
              }
              errorMessage={errors.city}
              value={values.city}
              disabled={isLoading}
            />
            <TextInput
              labelKey="Address"
              forText="address"
              name="address"
              isRequired
              placeHolder="Address"
              onChange={(value, event) => {
                handleChange(event);
              }}
              onBlur={handleBlur}
              changeState={
                touched.address && errors.address
                  ? InputChangeState.INVALID
                  : InputChangeState.VALID
              }
              errorMessage={errors.address}
              value={values.address}
              disabled={isLoading}
            />
            <div className="mt-4">
              <label
                htmlFor="facility-category"
                className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
              >
                Languages <span className="text-gray-400">*</span>
              </label>
              <Select
                name="supportedLanguages"
                id="languages-select"
                className="rounded-md h-10 mt-2 w-full"
                onChange={handleChange}
                native
                value={values.supportedLanguages}
              >
                {FACILITY_LANGUAGES_OPTIONS.map((language) => (
                  <option
                    value={language.value}
                    key={Math.random()}
                    className="hover:bg-grey-4 cursor-pointer"
                  >
                    {language.display}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex flex-row justify-between">
              <button
                className="button px-10 mt-6"
                onClick={() => {
                  goBack();
                }}
              >
                Back
              </button>
              <button
                className={`button px-10 mt-6 ${
                  !isFormValid || isLoading ? 'bg-primary-light c-no-drop' : ''
                }`}
                onClick={() => {
                  goNext(values);
                }}
                disabled={!isFormValid}
              >
                Next
                {isLoading && (
                  <CircularProgress style={{ color: 'white' }} size={15} className="ml-2" />
                )}
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

interface RegisterFacilityStepThreeProps {
  goNext: (nextState: typeof initialStateV) => void;
  goBack: () => void;
  initialState: typeof initialStateV;
}

export default RegisterFacilityStepThree;
