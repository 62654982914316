import React from 'react';
import PasswordReset from '../../../passwordReset';
import { ModalsOptions } from '../../../../@types/redux/appRessourceStates/modals';

export function modalsMatcher(currentModal: ModalsOptions | null, handleClose = () => {}) {
  switch (currentModal) {
    case ModalsOptions.PASSWORD_RESET_EMAIL:
      return <PasswordReset step="email" handleClose={handleClose} />;
    case ModalsOptions.PASSWORD_RESET_NEW_PASSWORD:
      return <PasswordReset step="verification-code" handleClose={handleClose} />;
    case ModalsOptions.PASSWORD_RESET_VERIFICATION_CODE:
      return <PasswordReset step="password" handleClose={handleClose} />;
  }
}

export default modalsMatcher;
