import React from 'react';

const RegisterFacilityStepFive: React.FC<RegisterFacilityStepFiveProps> = ({
  goHome = () => {},
}) => {
  const errorMessage = '';
  const isLoading = false;
  return (
    <div className="w-96">
      <h2 className="font-bold text-2xl py-6">Successful registration </h2>
      <div className="text-dark text-lg pb-2 ">
        <div className="text-primary font-semibold">Please check your email.</div>
        <br />
        <div>
          Our team will be reviewing your registration details. We will soon be in touch. <br />{' '}
          <br /> Thank you for choosing MOLA
        </div>
      </div>
      {errorMessage && <p className="text-sm text-red-600">({errorMessage})</p>}

      <div className="flex flex-row justify-between">
        <button
          className={`button px-10 mt-20 ${isLoading ? 'bg-primary-light c-no-drop' : ''}`}
          onClick={() => {
            goHome();
            window.location.href = `${process.env.REACT_APP_WEB_PORTAL_URL}`;
          }}
          id="login-submit-button"
        >
          Done
        </button>
      </div>
    </div>
  );
};

interface RegisterFacilityStepFiveProps {
  goHome: () => void;
}

export default RegisterFacilityStepFive;
