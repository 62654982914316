import React from 'react';
import { Formik, Form } from 'formik';
import { passwordResetInitValidationSchema } from '../../shared/schemas/registration';
import { _initPwdReset } from '../../store/actions/creators/registration';
import { connect, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { InputChangeState, TextInput } from '@mola/client-components';

const PasswordResetInit: React.FC<PasswordResetInitProps> = ({
  setNextStep = () => {},
  isLoading,
}) => {
  const dispatch = useDispatch();

  const next1 = async (values: any) => {
    if (await _initPwdReset({ email: values.email2 }, dispatch)) {
      setNextStep('verification-code');
    }
  };

  return (
    <Formik
      initialValues={{
        email2: '',
      }}
      validationSchema={passwordResetInitValidationSchema}
      onSubmit={(values) => {
        next1(values);
      }}
    >
      {(props) => (
        <div className="px-10">
          <h2 className="font-bold text-xl py-6">Forgot Password?</h2>
          <p className="text-primary text-lg pb-2">Provide your email for recovery</p>
          <Form>
            <TextInput
              labelKey="Email"
              forText="email2"
              name="email2"
              value={props.values.email2}
              onChange={(value, event) => {
                props.handleChange(event);
              }}
              changeState={
                props.touched.email2 && props.errors.email2
                  ? InputChangeState.INVALID
                  : InputChangeState.VALID
              }
              errorMessage={props.errors.email2}
              isRequired
              placeHolder="email"
            />
            <button className="button w-full mt-6" type="submit" disabled={isLoading}>
              Submit
              {isLoading && (
                <CircularProgress style={{ color: 'white' }} size={15} className="ml-2" />
              )}
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

interface PasswordResetInitProps {
  setNextStep: (value: 'email' | 'verification-code' | 'password') => void;
  isLoading: boolean;
}

const mapStateToProps = ({ registrationState: { isResettingPwd } }: StoreI) => ({
  isLoading: isResettingPwd,
});

export default connect(mapStateToProps)(PasswordResetInit);
