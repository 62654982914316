import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Please provide a valid email').required('Please provide an email'),
  password: Yup.string()
    .min(5, 'Password must be at least 5 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'Password must contain Capital And Small letters, a number and a special character',
    )
    .required('Please provide a password'),
});

export const passwordResetInitValidationSchema = Yup.object().shape({
  email2: Yup.string().email('Please provide a valid email').required('Please provide an email'),
});

export const passwordResetCodeValidationSchema = Yup.object().shape({
  verification_code: Yup.string().min(9).max(11).required('Please provide an code'),
});

export const passwordResetCompleteValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, 'Password must be at least 5 characters long')
    .required('Please provide a password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'Password must contain Capital And Small letters, a number and a special character',
    )
    .oneOf([Yup.ref('confirmPassword'), null], "Passwords don't match")
    .trim(),

  confirmPassword: Yup.string()
    .min(5, 'Password must be at least 5 characters long')
    .required('Please provide a password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'Password must contain Capital And Small letters, a number and a special character',
    )
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .trim(),
});
