import { AllowedRoles, arrayContainsSome, Tokens } from '@mola/client-components';

export default function handleRedirect(
  roles: AllowedRoles[],
  tokens: Tokens,
  _logout: () => void,
  tokensStorage: 'localStorage' | 'sessionStorage',
  logout?: boolean,
) {
  console.log(tokensStorage);
  if (logout) {
    _logout();
    location.href = `${process.env.REACT_APP_WEB_PORTAL_URL}`;
    return;
  }

  if (arrayContainsSome([AllowedRoles.MOLA_ADMIN, AllowedRoles.SUPER_ADMIN], roles)) {
    location.href = `${process.env.REACT_APP_ADMIN_PORTAL_URL}/authorize/?&accessToken=${tokens.access}&refreshToken=${tokens.refresh}&tokensStorage=${tokensStorage}`;
    return;
  }

  if (arrayContainsSome([AllowedRoles.FACILITY_ADMIN, AllowedRoles.FACILITY_RECEPTIONIST], roles)) {
    location.href = `${process.env.REACT_APP_FACILITY_PORTAL_URL}/authorize/?&accessToken=${tokens.access}&refreshToken=${tokens.refresh}&tokensStorage=${tokensStorage}`;
    return;
  }

  location.pathname = '/notFound';
  return;
}
