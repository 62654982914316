import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AppSvgLogoBig } from '../../assets/svg/app-logo-big.svg';
import { ModalsState } from '../../@types/redux/appRessourceStates/modals';
import { connect, useDispatch } from 'react-redux';
import { setCurrentModalType } from '../../store/actions/creators/modals';
import { login, setTokensStorage } from '../../store/actions/creators/registration';
import RegisterFacilityStepOne from '../../components/facilities/RegisterStepOne';
import RegisterFacilityStepTwo from '../../components/facilities/RegisterStepTwo';
import RegisterFacilityStepThree from '../../components/facilities/RegisterStepThree';
import RegisterFacilityStepFour from '../../components/facilities/RegisterStepFour';
import { ReactComponent as LoginSvgBackground } from '../../assets/svg/facility-registration-in-progress-left-icon.svg';
import { ReactComponent as CompletedSideLogo } from '../../assets/svg/facility-registration-complete-side.svg';
import RegisterFacilityStepFive from '../../components/facilities/RegisterStepFive';
import { createFacility } from '../../store/actions/creators/facilities';
import {
  GeneralLayout,
  FACILITY_CATEGORIES_OPTIONS,
  FACILITY_TYPES_OPTIONS,
} from '@mola/client-components';

export enum FacilitySteps {
  PRIMARY_DETAILS = 1,
  ABOUT_DETAILS = 2,
  ADDRESS_AND_LANGUAGE_DETAILS = 3,
  FACILITY_ADMIN_DETAILS = 4,
  SUCCESS = 5,
}

const step1InitialState = {
  name: '',
  type: FACILITY_TYPES_OPTIONS[1].value,
};

const step2InitialState = {
  category: FACILITY_CATEGORIES_OPTIONS[0].value,
  description: '',
  website: '',
};

const step3InitialState = {
  country: 'Kenya',
  city: '',
  address: '',
  supportedLanguages: 'English',
};

const step4InitialState = {
  email: '',
  password: '',
  name: '',
  phoneNumber: '',
};

type FieldsT = Merge<
  Merge<typeof step1InitialState, typeof step2InitialState>,
  Merge<typeof step3InitialState, { admin: typeof step4InitialState }>
>;

const RegisterFacility: React.FC<LoginProps> = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState<FacilitySteps>(FacilitySteps.PRIMARY_DETAILS);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<FieldsT>({
    ...step1InitialState,
    ...step2InitialState,
    ...step3InitialState,
    admin: { ...step4InitialState },
  });

  const [image, setImage] = useState<File>();

  const getCurrentStep = () => {
    switch (currentStep) {
      case FacilitySteps.PRIMARY_DETAILS:
        return (
          <RegisterFacilityStepOne
            goNext={(next, imageToSave: File) => {
              setFields({
                ...fields,
                ...next,
              });
              setImage(imageToSave);
              setCurrentStep(FacilitySteps.ABOUT_DETAILS);
            }}
            initialState={{
              type: fields.type,
              name: fields.name,
            }}
          />
        );
      case FacilitySteps.ABOUT_DETAILS:
        return (
          <RegisterFacilityStepTwo
            goNext={(newState) => {
              setFields({ ...fields, ...newState });
              setCurrentStep(FacilitySteps.ADDRESS_AND_LANGUAGE_DETAILS);
            }}
            goBack={() => {
              setCurrentStep(FacilitySteps.PRIMARY_DETAILS);
            }}
            initialState={{
              description: fields.description,
              website: fields.website,
              category: fields.category,
            }}
          />
        );
      case FacilitySteps.ADDRESS_AND_LANGUAGE_DETAILS:
        return (
          <RegisterFacilityStepThree
            goNext={(nextState) => {
              setCurrentStep(FacilitySteps.FACILITY_ADMIN_DETAILS);
              setFields({
                ...fields,
                ...nextState,
              });
            }}
            goBack={() => {
              setCurrentStep(FacilitySteps.ABOUT_DETAILS);
            }}
            initialState={{
              address: fields.address,
              city: fields.city,
              country: fields.country,
              supportedLanguages: fields.supportedLanguages,
            }}
          />
        );
      case FacilitySteps.FACILITY_ADMIN_DETAILS:
        return (
          <RegisterFacilityStepFour
            goNext={(nextState) => {
              setIsLoading(true);
              setFields({ ...fields, admin: { ...fields.admin, ...nextState } });
              createFacility({
                ...fields,
                admin: { ...fields.admin, ...nextState },
                phoneNumber: nextState.phoneNumber,
                image,
              })(dispatch).then(({ payload }) => {
                setIsLoading(false);
                if (typeof payload !== 'string') {
                  setCurrentStep(FacilitySteps.SUCCESS);
                }
              });
            }}
            goBack={() => {
              setCurrentStep(FacilitySteps.ADDRESS_AND_LANGUAGE_DETAILS);
            }}
            initialState={fields.admin}
            isLoading={isLoading}
          />
        );
      case FacilitySteps.SUCCESS:
        return <RegisterFacilityStepFive goHome={() => {}} />;
    }
  };

  return (
    <div className="container mx-auto lg:px-32 login">
      <div className="login-header flex flex-col justify-center">
        <div className="flex flex-row justify-between">
          <AppSvgLogoBig
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_WEB_PORTAL_URL}`;
            }}
            className="cursor-pointer"
          />
          <div className="">
            <button
              className="mr-6 font-semibold text-lg button bg-white text-dark"
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_WEB_PORTAL_URL}`;
              }}
            >
              Home
            </button>
            {process.env.REACT_APP_VERSION !== '0.0.1' && (
              <button
                className="button py-3 bg-white rounded-lg border-2 border-primary text-primary font-bold"
                onClick={() => {
                  push('/login?&scope=facilityLogin');
                }}
              >
                Sign In as facility
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="block lg:flex flex-row justify-between login-content">
        {getCurrentStep()}
        {currentStep === FacilitySteps.SUCCESS ? (
          <CompletedSideLogo className="h-screen-55 hidden lg:block" />
        ) : (
          <LoginSvgBackground className="h-screen-55 hidden lg:block" />
        )}
      </div>
    </div>
  );
};

interface LoginProps {
  _setCurrentModalType: (options: ModalsState['currentModal']) => {
    type: string;
    payload: ModalsState['currentModal'];
  };
  _setTokensStorage: (storage: 'localStorage' | 'sessionStorage') => {
    type: string;
    payload: string;
  };
  isLoading: boolean;
  _login: (formData: { email: string; password: string }) => Promise<{
    type: string;
    payload:
      | {
          isAuth: boolean;
          user: UserI;
          storage: 'localStorage' | 'sessionStorage';
        }
      | string;
  }>;
  errorMessage: string | null;
}

const mapStateToProps = ({ registrationState: { isLoading, errorMessage } }: StoreI) => ({
  isLoading,
  errorMessage,
});

export default connect(mapStateToProps, {
  _setCurrentModalType: setCurrentModalType,
  _setTokensStorage: setTokensStorage,
  _login: login,
})(GeneralLayout(RegisterFacility));
