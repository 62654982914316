export enum RegistrationActionTypes {
  LOGOUT = 'Registration/LOGOUT',
  SET_CURRENT_USER = 'Registration/SET_CURRENT_USER',
  TOGGLE_LOGIN_FORM = 'Registration/TOGGLE_LOGIN_FORM',
  SET_TOKENS_VALUE = 'Registration/SET_TOKENS_VALUE',
  SET_TOKENS_STORAGE = 'Registration/SET_TOKENS_STORAGE',
  REGISTRATION_ERROR = 'Registration/REGISTRATION_ERROR',
  SET_IS_LOADING = 'Registration/SET_IS_LOADING',
  SET_IS_RESETTING_PWD = 'Registration/SET_IS_RESETTING_PWD',
}
