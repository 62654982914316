import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { RegistrationState } from '../@types/redux/appRessourceStates/registration';
import * as queryString from 'querystring';
import handleRedirect from '../shared/utils/handleRedirect';
import { AllowedRoles } from '@mola/client-components';
import { logout } from '../store/actions/creators/registration';

const AuthRedirect: React.FC<DashboardProps> = ({
  currentUser,
  tokens,
  _logout,
  tokensStorage,
}) => {
  const parsed = queryString.parse(location.search);
  handleRedirect(
    currentUser?.roles as AllowedRoles[],
    tokens,
    _logout,
    tokensStorage,
    Boolean(parsed.logout),
  );

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CircularProgress size={30} className="ml-2" />
    </div>
  );
};

interface DashboardProps {
  currentUser: RegistrationState['currentUser'];
  tokens: RegistrationState['tokens'];
  _logout: () => void;
  tokensStorage: 'localStorage' | 'sessionStorage';
}

const mapStateToProps = ({
  registrationState: { currentUser, tokens, tokensStorage },
}: StoreI) => ({
  currentUser,
  tokens,
  tokensStorage,
});

export default connect(mapStateToProps, {
  _logout: logout,
})(AuthRedirect);
