import { Dispatch } from 'redux';
import { RegistrationActionTypes } from '../types/registration';
import server from '../../../services/axios.service';
import { saveImage } from '../../../shared/helpers/saveImage';

export const createFacility =
  (formData: {
    name: string;
    type: string;
    category: string;
    description: string;
    website: string;
    country: string;
    city: string;
    address: string;
    supportedLanguages: string;
    phoneNumber: string;
    admin: {
      email: string;
      password: string;
      name: string;
      phoneNumber: string;
    };
    image: File | undefined;
    logo?: string | null;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      if (formData.image) {
        formData.logo = await saveImage(formData.image);
      }

      delete formData.image;

      const {
        data: { data },
      } = await server.post<ServerResponseT<{ facility: FacilityI }>>('/facilities', formData);
      return {
        type: 'Success',
        payload: data,
      };
    } catch (e: any) {
      if (
        e.response &&
        e.response.status === 406 &&
        e.response.data.message === 'Phone number already in use'
      ) {
        return dispatch({
          type: RegistrationActionTypes.REGISTRATION_ERROR,
          payload: 'Phone number already in use',
        });
      } else if (
        e.response &&
        e.response.status === 406 &&
        e.response.data.message === 'Admin Email already in use'
      ) {
        return dispatch({
          type: RegistrationActionTypes.REGISTRATION_ERROR,
          payload: 'Admin Email already in use',
        });
      }
      return dispatch({
        type: RegistrationActionTypes.REGISTRATION_ERROR,
        payload: 'Something went wrong',
      });
    }
  };
