import React, { useState } from 'react';
import PasswordResetInit from './PwdResetInit';
import PasswordResetCode from './PwdResetCode';
import PasswordComplete from './PwdResetComplete';

const PasswordReset: React.FC<PasswordResetProps> = ({ step, handleClose = () => {} }) => {
  const [localStep, setLocalStep] = useState(step);

  switch (localStep) {
    case 'email':
      return (
        <PasswordResetInit
          setNextStep={(nextStep) => {
            setLocalStep(nextStep);
          }}
        />
      );
    case 'verification-code':
      return (
        <PasswordResetCode
          setNextStep={(nextStep) => {
            setLocalStep(nextStep);
          }}
        />
      );
    case 'password':
      return (
        <PasswordComplete
          setFirstStep={(firstStep) => {
            setLocalStep(firstStep);
          }}
          handleClose={() => {
            handleClose();
          }}
        />
      );
  }
};

interface PasswordResetProps {
  step: 'email' | 'verification-code' | 'password';
  handleClose?: () => void;
}

export default PasswordReset;
