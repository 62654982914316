import React, { useState } from 'react';
import { CircularProgress, Select } from '@mui/material';
import { ReactComponent as StepIcon } from '../../assets/svg/4StepsSterpper-step1.svg';
import { Formik } from 'formik';
import { registerStep1ValidationSchema } from '../../shared/schemas/facilities';
import {
  ImagePicker,
  InputChangeState,
  TextInput,
  FACILITY_TYPES_OPTIONS,
} from '@mola/client-components';
import { ReactComponent as ImageUploadSvg } from '../../assets/svg/image-upload-svg.svg';
import { ReactComponent as ImagePickerIcon } from '../../assets/svg/image-picker-icon.svg';

const initialStateV = {
  name: '',
  type: FACILITY_TYPES_OPTIONS[1].value,
};

const RegisterFacilityStepOne: React.FC<RegisterFacilityStepOneProps> = ({
  goNext,
  initialState,
}) => {
  const errorMessage = '';
  const isLoading = false;
  const [image, setImage] = useState<File>();

  return (
    <Formik
      onSubmit={() => {
        // _login(values);
      }}
      validateOnChange
      initialValues={initialState}
      validationSchema={registerStep1ValidationSchema}
    >
      {(props) => {
        const { touched, errors, isValid, handleChange, handleBlur, values } = props;

        const isFormValid = isValid && !errors.name && values.name.length > 0;

        return (
          <div className="w-96">
            <h2 className="font-bold text-2xl py-6">Register facility </h2>
            <p className="text-primary text-xl pb-2 flex flex-row justify-between">
              Primary details <StepIcon className="mt-3" />
            </p>
            {errorMessage && <p className="text-sm text-red-600">({errorMessage})</p>}
            <ImagePicker
              onImageUpload={(imageFile) => {
                setImage(imageFile);
              }}
              className="mt-6"
              ImageUploadSvg={ImageUploadSvg}
              ImagePickerIcon={ImagePickerIcon}
            />
            <TextInput
              labelKey="Facility name"
              forText="facility-name"
              name="name"
              isRequired
              classes={{}}
              onChange={(value, event) => {
                handleChange(event);
              }}
              placeHolder="Facility name"
              onBlur={handleBlur}
              changeState={
                touched.name && errors.name ? InputChangeState.INVALID : InputChangeState.VALID
              }
              errorMessage={errors.name}
              value={values.name}
              disabled={isLoading}
            />
            <div className="mt-4">
              <label
                htmlFor="facility-category"
                className="text-xs sm:text-sm md:text-base text-dark-1 font-semibold block w-full"
              >
                Facility Type <span className="text-gray-400">*</span>
              </label>
              <Select
                name="type"
                id="facility-types"
                className="rounded-md h-10 mt-2 px-1 w-full"
                onChange={handleChange}
                native
                onBlur={handleBlur}
                value={values.type}
                disabled={isLoading}
              >
                {[...FACILITY_TYPES_OPTIONS].splice(1, FACILITY_TYPES_OPTIONS.length).map((el) => (
                  <option
                    value={el.value}
                    key={Math.random()}
                    className="hover:bg-grey-4 cursor-pointer"
                  >
                    {el.value}
                  </option>
                ))}
              </Select>
            </div>

            <div className="text-right">
              <button
                className={`button px-10 mt-6 ${
                  !isFormValid || isLoading ? 'bg-primary-light c-no-drop' : ''
                }`}
                onClick={() => {
                  goNext(values, image);
                }}
                disabled={!isFormValid}
              >
                Next
                {isLoading && (
                  <CircularProgress style={{ color: 'white' }} size={15} className="ml-2" />
                )}
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

interface RegisterFacilityStepOneProps {
  goNext: (state: typeof initialStateV, image: any) => void;
  initialState: typeof initialStateV;
}

export default RegisterFacilityStepOne;
