import React from 'react';
import { Formik, Form } from 'formik';
import { passwordResetCodeValidationSchema } from '../../shared/schemas/registration';
import { _initPwdReset, _submitPwdResetCode } from '../../store/actions/creators/registration';
import { connect, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { InputChangeState, TextInput } from '@mola/client-components';

const PasswordResetCode: React.FC<PasswordCodeInitProps> = ({
  setNextStep = () => {},
  isLoading,
}) => {
  const dispatch = useDispatch();
  const next2 = async (values: any) => {
    if (await _submitPwdResetCode({ code: values.verification_code }, dispatch)) {
      setNextStep('password');
    }
  };
  return (
    <Formik
      initialValues={{
        verification_code: '',
      }}
      validationSchema={passwordResetCodeValidationSchema}
      onSubmit={(values) => {
        next2(values);
      }}
    >
      {(props) => (
        <div className="px-10">
          <h2 className="font-bold text-xl py-6">Forgot Password?</h2>
          <p className="text-primary text-lg pb-2">Provide verification code sent to your email</p>
          <Form>
            <TextInput
              labelKey="Verification Code"
              forText="verification_code"
              isRequired
              value={props.values.verification_code}
              onChange={(value, event) => {
                props.handleChange(event);
              }}
              changeState={
                props.touched.verification_code && props.errors.verification_code
                  ? InputChangeState.INVALID
                  : InputChangeState.VALID
              }
              errorMessage={props.errors.verification_code}
              placeHolder="Verification code"
            />

            <div className="flex flex-row justify-between pt-6">
              <span>Havent got the code?</span>
              <button
                className="text-primary"
                onClick={() => {
                  _initPwdReset(
                    { email: localStorage.getItem('reset-pwd-email') as string },
                    dispatch,
                  ).then(() => {});
                }}
              >
                Resend the code {isLoading && '...'}
              </button>
            </div>
            <button className="button w-full mt-6" type="submit" disabled={isLoading}>
              Submit
              {isLoading && (
                <CircularProgress style={{ color: 'white' }} size={15} className="ml-2" />
              )}
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

interface PasswordCodeInitProps {
  setNextStep: (value: 'email' | 'verification-code' | 'password') => void;
  isLoading: boolean;
}

const mapStateToProps = ({ registrationState: { isResettingPwd } }: StoreI) => ({
  isLoading: isResettingPwd,
});

export default connect(mapStateToProps)(PasswordResetCode);
