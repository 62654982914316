import React, { lazy, Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import ModalsContainer from '../components/Shared/Layouts/modals/ModalsContainer';
import { appHistory } from '../shared/utils/sharedHistory';
import { setCurrentUser } from '../store/actions/creators/registration';
import { connect } from 'react-redux';
import { RegistrationState } from '../@types/redux/appRessourceStates/registration';
import AuthRedirect from '../pages/AuthRedirect';
import GuardedRoute from './GuardedRoute';
import RegisterFacility from '../pages/Facilities/Register';
import {
  CustomSuspenseToNotFound,
  SuspenseSpinner,
  AllowedRoles,
  arrayContainsSome,
  getTokens,
  Tokens,
} from '@mola/client-components';

const Login = lazy(() => import('../components/Auth/Login'));

const Routes: React.FC<RoutesProps> = ({ currentUser, _setCurrentUser, isAuth }) => {
  const storageTokens = getTokens();

  const foundTokens = storageTokens && storageTokens.tokens.access && storageTokens.tokens.refresh;

  if (!currentUser && foundTokens && storageTokens.storage === 'localStorage') {
    _setCurrentUser(storageTokens.tokens, true, 'localStorage');
  } else if (!isAuth && foundTokens && storageTokens.storage === 'sessionStorage') {
    _setCurrentUser(storageTokens.tokens, true, 'sessionStorage');
  }

  const handleAuthStatus = () => {
    if (
      currentUser &&
      arrayContainsSome([AllowedRoles.MOLA_ADMIN, AllowedRoles.SUPER_ADMIN], currentUser.roles)
    ) {
      return <Redirect to="/auth/redirect" />;
    }

    return <Login />;
  };

  return (
    <>
      <Router history={appHistory}>
        <Suspense fallback={<SuspenseSpinner />}>
          <Switch>
            <Route exact path="/facility-register" component={RegisterFacility} />

            {process.env.REACT_APP_VERSION !== '0.0.1' && (
              <>
                <Route exact path="/" component={() => handleAuthStatus()} />
                <Route exact path="/login" component={Login} />
                <GuardedRoute exact path="/auth/redirect" Component={AuthRedirect} />
              </>
            )}

            <Route exact path="*" component={CustomSuspenseToNotFound} />
          </Switch>
        </Suspense>
      </Router>
      <ModalsContainer />
    </>
  );
};

interface RoutesProps {
  isAuth: boolean;
  _setCurrentUser: (
    tokens: Tokens,
    isAuth: boolean,
    storage: 'localStorage' | 'sessionStorage',
  ) => Promise<{ type: string; payload: { isAuth: boolean; user: UserI } }>;
  currentUser: RegistrationState['currentUser'];
}

const mapStateToProps = ({ registrationState: { isAuth, currentUser } }: StoreI) => ({
  isAuth,
  currentUser,
});

export default connect(mapStateToProps, {
  _setCurrentUser: setCurrentUser,
})(Routes);
