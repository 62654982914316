import React from 'react';
import { Formik, Form } from 'formik';
import { passwordResetCompleteValidationSchema } from '../../shared/schemas/registration';
import { _completePwdReset } from '../../store/actions/creators/registration';
import { connect, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { InputChangeState, PasswordInput } from '@mola/client-components';
import { ReactComponent as EyeXIcon } from '../../assets/svg/eye-x-icon.svg';
import { ReactComponent as EyeIcon } from '../../assets/svg/eye-icon.svg';

const PasswordComplete: React.FC<PasswordResetCompleteProps> = ({
  setFirstStep = () => {},
  handleClose = () => {},
  isLoading,
}) => {
  const dispatch = useDispatch();
  const next3 = async (values: any) => {
    if (await _completePwdReset({ password: values.password }, dispatch)) {
      handleClose();
    } else {
      setFirstStep('email');
    }
  };
  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validateOnChange
      validationSchema={passwordResetCompleteValidationSchema}
      onSubmit={(values) => {
        next3(values);
      }}
    >
      {(props) => (
        <div className="px-10">
          <h2 className="font-bold text-xl py-6">Forgot Password?</h2>
          <p className="text-primary text-lg pb-2">Set new password</p>
          <Form>
            <PasswordInput
              labelKey="New Password"
              forText="password"
              value={props.values.password}
              onChange={(value, event) => {
                props.handleChange(event);
              }}
              changeState={
                props.touched.password && props.errors.password
                  ? InputChangeState.INVALID
                  : InputChangeState.VALID
              }
              errorMessage={props.errors.password}
              isRequired
              placeHolder="password"
              getEyeIcons={() => ({
                EyeIcon: <EyeIcon />,
                EyeXIcon: <EyeXIcon />,
              })}
            />
            <PasswordInput
              labelKey="Confirm Password"
              forText="confirmPassword"
              value={props.values.confirmPassword}
              onChange={(value, event) => {
                props.handleChange(event);
              }}
              changeState={
                props.touched.password && props.errors.confirmPassword
                  ? InputChangeState.INVALID
                  : InputChangeState.VALID
              }
              errorMessage={props.errors.confirmPassword}
              isRequired
              placeHolder="password"
              classes={{
                container: 'mt-6',
              }}
              getEyeIcons={() => ({
                EyeIcon: <EyeIcon />,
                EyeXIcon: <EyeXIcon />,
              })}
            />
            <button className="button w-full mt-6" type="submit" disabled={isLoading}>
              Submit
              {isLoading && (
                <CircularProgress style={{ color: 'white' }} size={15} className="ml-2" />
              )}
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

interface PasswordResetCompleteProps {
  setFirstStep: (value: 'email' | 'verification-code' | 'password') => void;
  handleClose: () => void;
  isLoading: boolean;
}

const mapStateToProps = ({ registrationState: { isResettingPwd } }: StoreI) => ({
  isLoading: isResettingPwd,
});

export default connect(mapStateToProps)(PasswordComplete);
